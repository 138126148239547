import { UsePriceOfferDeliveryDateProps } from './usePriceOffer.types'
import { getPriceOfferOrderDelivery } from '../../../services/api/price-offer/PriceOffer.service'
import { useCurrentUser } from '../../../auth/auth'
import { useQuery } from '@tanstack/react-query'
import useAxios from '../../useAxios'

const usePriceOfferOrderDeliveryDate = ({
    priceOfferId,
    paramsString,
    enabled,
    onError,
    onSuccess
}: UsePriceOfferDeliveryDateProps) => {
    const { isReady, isLogged } = useCurrentUser()
    const axiosInstance = useAxios()

    return useQuery(
        ['priceOffer-delivery', priceOfferId, paramsString],
        async ({ signal }) => {
            return getPriceOfferOrderDelivery(axiosInstance, priceOfferId, paramsString, { signal })
        },
        {
            enabled: isReady && isLogged && enabled,
            onError,
            onSuccess
        }
    )
}

export default usePriceOfferOrderDeliveryDate
