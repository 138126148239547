import { useCallback } from 'react'

const usePrint = () => {
    return useCallback(async (blob: Blob) => {
        const blobUrl = URL.createObjectURL(blob)
        const iframe = document.createElement('iframe')

        iframe.style.position = 'absolute'
        iframe.style.width = '0'
        iframe.style.height = '0'
        iframe.style.left = '0'
        iframe.style.top = '0'
        iframe.style.border = 'none'

        document.body.appendChild(iframe)

        iframe.onload = () => {
            setTimeout(() => {
                iframe.focus()
                iframe.contentWindow.print()
                setTimeout(() => {
                    return document.body.removeChild(iframe)
                }, 1000)
            }, 1)
        }

        iframe.src = blobUrl
    }, [])
}

export default usePrint
