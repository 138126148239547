import { AxiosInstance, AxiosRequestConfig } from 'axios/index'
import { PdfPart } from './PdfGeneratorService.types'
import { serialize } from 'object-to-formdata'

export const generatePdf = async (
    axiosInstance: AxiosInstance,
    parts: PdfPart[],
    config: AxiosRequestConfig = {}
): Promise<Blob> => {
    const formData = serialize(
        {
            parts
        },
        { booleansAsIntegers: true, indices: true }
    )

    config = {
        ...config,
        responseType: config.responseType || 'blob',
        headers: {
            ...(config.headers || {}),
            'Content-Type': 'multipart/form-data'
        }
    }

    const { data: pdf } = await axiosInstance.post<Blob>('pdf-merge', formData, config)

    return pdf
}
