import { AxiosError } from 'axios'
import { MakeAxiosPaginatedResponse } from '../../../services/Service.types'
import { PriceOfferShort } from '../../../services/api/price-offer/PriceOffer.types'
import { deleteOrderFromPriceOffer } from '../../../services/api/price-offer/PriceOffer.service'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import useAxios from '../../useAxios'

const usePriceOfferDeleteOrder = () => {
    const axiosInstance = useAxios()
    const queryClient = useQueryClient()

    return useMutation<void, AxiosError, { id: number; orderId: number }>(
        ['deletePriceOfferOrder'],
        ({ id, orderId }) => {
            return deleteOrderFromPriceOffer(axiosInstance, id, orderId)
        },
        {
            onSuccess: (data, { id, orderId }) => {
                queryClient.setQueriesData<MakeAxiosPaginatedResponse<PriceOfferShort>>(['priceOffers'], prev => {
                    return {
                        ...prev,
                        data: prev.data.map(item => {
                            return {
                                ...item,
                                orders:
                                    item.id === id
                                        ? item.orders.filter(order => {
                                              return order.id !== orderId
                                          })
                                        : item.orders,
                                variants: item.variants
                                    .filter(variant => {
                                        return variant.id === id
                                    })
                                    .map(variant => {
                                        return {
                                            ...variant,
                                            orders: variant.orders.filter(order => {
                                                return order.id !== orderId
                                            })
                                        }
                                    })
                            }
                        })
                    }
                })
            }
        }
    )
}

export default usePriceOfferDeleteOrder
