import { Axios } from 'axios'
import { PdfPart, PdfPartType } from '../../../../services/api/pdf-generator/PdfGeneratorService.types'
import {
    PriceOfferStepTwoDetail,
    PriceOfferStepTwoLayoutFileSection
} from '../../../../services/api/price-offer/PriceOffer.types'
import { fileToDataUri } from '../../../../utils/Helper'
import { renderToStaticMarkup } from 'react-dom/server'
import PriceOfferPdf from './PriceOfferPdf'
import React from 'react'

const loadPriceOfferPdfImages = async (axios: Axios, data: PriceOfferStepTwoDetail) => {
    //Load images
    const allImages = data.layout
        .filter(item => {
            return item.type.startsWith('images') || item.type.startsWith('banners') || item.type.startsWith('banner')
        })
        .reduce(
            (images, section) => {
                const imageSection = section as PriceOfferStepTwoLayoutFileSection
                images.push(
                    ...imageSection.data.map(item => {
                        return { url: item.fileUrl, alt: item.filePrettyName }
                    })
                )
                return images
            },
            [] as { url: string; alt: string }[]
        )

    //Push tenderer logo
    if (data.header.tendererLogoUrl) {
        allImages.push({
            url: data.header.tendererLogoUrl,
            alt: 'Logo'
        })
    }

    return await Promise.all(
        allImages.map(async image => {
            const { data } = await axios.get<Blob>(image.url, { responseType: 'blob' })
            return {
                ...image,
                base64: await fileToDataUri(data)
            }
        })
    )
}

export const generatePriceOfferPdfParts = async (axios: Axios, data: PriceOfferStepTwoDetail, currency: string) => {
    const loadedImages = await loadPriceOfferPdfImages(axios, data)
    const layout = data.layout
    const sections: PdfPart[] = []
    let isFirstHtmlSection = true
    const hasFilesSection = layout.some(item => {
        return item.type.startsWith('files')
    })

    if (hasFilesSection) {
        for (let i = 0; i < layout.length; i++) {
            let item = layout[i]

            if (item.type.startsWith('files')) {
                item = item as PriceOfferStepTwoLayoutFileSection
                sections.push(
                    ...item.data.map(file => {
                        return {
                            type: 'pdf-local' as PdfPartType,
                            content: file.fileUrl
                        }
                    })
                )
            } else {
                const htmlSections = []

                if (isFirstHtmlSection) {
                    htmlSections.push('header')
                    isFirstHtmlSection = false
                }

                while (i < layout.length && !layout[i].type.startsWith('files')) {
                    htmlSections.push(layout[i].type)
                    i++
                }

                const html = renderToStaticMarkup(
                    <PriceOfferPdf
                        data={data}
                        wrapToHtml
                        images={loadedImages}
                        renderSections={htmlSections}
                        currency={currency}
                    />
                )

                sections.push({
                    type: 'html-text',
                    content: html
                })
            }
        }
    } else {
        const html = renderToStaticMarkup(
            <PriceOfferPdf data={data} wrapToHtml images={loadedImages} currency={currency} />
        )

        sections.push({
            type: 'html-text',
            content: html
        })
    }

    return sections
}
