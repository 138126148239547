import { AxiosError } from 'axios'
import { priceOfferSendToProduction } from '../../../services/api/price-offer/PriceOffer.service'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import useAxios from '../../useAxios'

export type PriceOfferSendToProductionType = {
    id: number
    expedition: string
    expedition_n: string
    ts_delivery: string
    dpd_pay: boolean
}

const usePriceOfferSendToProduction = ({
    onError,
    onSuccess
}: {
    onError?: (error: AxiosError) => void
    onSuccess?: () => void
}) => {
    const axiosInstance = useAxios()
    const queryClient = useQueryClient()

    return useMutation<any, AxiosError, { priceOfferId: number; orders: PriceOfferSendToProductionType[] }>(
        ['priceOfferSendToProduction'],
        async ({ priceOfferId, orders }) => {
            return await priceOfferSendToProduction(axiosInstance, priceOfferId, orders)
        },
        {
            onSuccess: (data, priceOfferId) => {
                queryClient.setQueryData(['priceOffer', priceOfferId], data)
                queryClient.invalidateQueries(['priceOffers'])
                onSuccess?.()
            },
            onError
        }
    )
}

export default usePriceOfferSendToProduction
