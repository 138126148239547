import { FormProvider, useForm } from 'react-hook-form'
import { PriceOfferStepTwoDetail } from '../../../../services/api/price-offer/PriceOffer.types'
import { Trans, useTranslation } from 'next-i18next'
import { format } from 'date-fns'
import { formatDateToIso } from '../../../../utils/DateHelper'
import { handleDefaultSelectsValues } from '../../../../utils/OrdersFormHelper'
import { handleFormErrorsFromAxios } from '../../../../utils/ApiHelper'
import Alert from '../../../base/alert/Alert'
import Button from '../../../base/button/Button'
import Column from '../../../base/grid/Column'
import Modal, { ModalProps } from '../../../base/modal/Modal'
import ModalText from '../../../base/modal/ModalText'
import ModalTitle from '../../../base/modal/ModalTitle'
import OrderDemandSendToProductionTable, {
    OrdersFormValues
} from '../../order-demand-table/OrderDemandSendToProductionTable'
import React, { useCallback, useMemo } from 'react'
import Skeleton from 'react-loading-skeleton'
import axios from 'axios'
import usePriceOfferGet from '../../../../hooks/api/price-offer/usePriceOfferGet'
import usePriceOfferOrderDeliveryDate from '../../../../hooks/api/price-offer/usePriceOfferOrderDeliveryDate'
import usePriceOfferSendToProduction from '../../../../hooks/api/price-offer/usePriceOfferSendToProduction'
import useToast from '../../../../hooks/useToast'

export type DemandFormStepThreeSendToProductionModalProps = Omit<ModalProps, 'show'> & {
    show?: boolean
    onClose?: () => void
    priceOfferId: number
    onSuccessSendToProduction: () => void
    step?: string
}

const PriceOfferSendToProductionModal = ({
    show,
    onClose,
    priceOfferId,
    onSuccessSendToProduction,
    step
}: DemandFormStepThreeSendToProductionModalProps) => {
    const { t } = useTranslation('common')
    const { data: priceOffer, isLoading: isLoadingPriceOfferInfo } = usePriceOfferGet({
        id: priceOfferId
    })
    const toast = useToast()
    const { mutate: sendToProduction, isLoading: isLoadingSendToProduction } = usePriceOfferSendToProduction({
        onError: error => {
            if (axios.isAxiosError(error)) {
                handleFormErrorsFromAxios(error, setError)
            }
        },
        onSuccess: async () => {
            toast.success('Cenová nabídka byla odeslána do výroby.')
            await onSuccessSendToProduction()
        }
    })

    const typedPriceOfferStepOne = step === 'step_1' && priceOffer
    const typedPriceOfferStepTwo =
        (step === 'step_2' || step === 'step_3') && (priceOffer as unknown as PriceOfferStepTwoDetail)

    const ordersForm = useForm<OrdersFormValues>({
        values: {
            orders:
                step === 'step_1'
                    ? typedPriceOfferStepOne?.orders.map(order => {
                          return {
                              orderId: order.id,
                              productName: order.productName,
                              number: order.number,
                              expedition: '',
                              expedition_n: '',
                              dpd_pay: false,
                              ts_delivery: ''
                          }
                      }) ?? []
                    : typedPriceOfferStepTwo?.header.orders.map(order => {
                          return {
                              orderId: order.id,
                              productName: order.productName,
                              number: order.number,
                              expedition: '',
                              expedition_n: '',
                              dpd_pay: false,
                              ts_delivery: ''
                          }
                      }) ?? []
        }
    })

    const { watch, setValue, handleSubmit, setError } = ordersForm
    const ordersWatch = watch('orders')

    const getIsDpdExpedition = useCallback(() => {
        return ordersWatch
            .map(order => {
                return order.expedition?.toLowerCase().includes('dpd') || false
            })
            .some(value => {
                return value
            })
    }, [ordersWatch])

    const isDpdExpedition = getIsDpdExpedition()

    const params = useMemo(() => {
        return ordersWatch
            .filter(product => {
                return Boolean(product.ts_delivery)
            })
            .map(element => {
                return {
                    orderId: element.orderId,
                    ts_delivery: format(new Date(element.ts_delivery), 'dd.MM.yyyy')
                }
            })
    }, [ordersWatch])

    const paramsString = useMemo(() => {
        return params
            .map(({ orderId, ts_delivery }) => {
                return `date[${orderId}]=${ts_delivery}`
            })
            .join('&')
    }, [params])

    const {
        data,
        error,
        isLoading: isLoadingDeliveryDate
    } = usePriceOfferOrderDeliveryDate({
        priceOfferId: priceOfferId,
        paramsString: paramsString,
        onSuccess: dataForSelect => {
            dataForSelect.forEach((item, index) => {
                const tsDelivery = ordersWatch?.[index]?.ts_delivery
                handleDefaultSelectsValues(item.expeditions, setValue, tsDelivery ?? '', index)
            })
        }
    })

    const renderDpdDescription = () => {
        return (
            <Alert color='info' className='flex text-body-small text-left '>
                <div className='mt-1'>
                    <Trans
                        t={t}
                        i18nKey='frontend.dashboard.demands_orders.detail.delivery.dpd_info'
                        components={{
                            p: <p />,
                            ul: <ul className='pl-6 list-disc py-5' />,
                            li: <li />,
                            strong: <strong />,
                            br: <br />
                        }}
                    />
                </div>
            </Alert>
        )
    }

    const handleSendToProduction = handleSubmit(requestData => {
        sendToProduction({
            priceOfferId,
            orders: requestData.orders.map(order => {
                return {
                    id: order.orderId,
                    ts_delivery: formatDateToIso(order.ts_delivery),
                    expedition: order.expedition,
                    expedition_n: order.expedition_n,
                    dpd_pay: order.dpd_pay
                }
            })
        })
    })

    const renderActions = () => {
        return (
            <div className='flex flex-col gap-1 mt-[20px] w-[50%]'>
                <Button onClick={handleSendToProduction} type='submit' loading={isLoadingSendToProduction}>
                    {t('frontend.price_offers.sent_to_production_modal.action.confirm')}
                </Button>
                <Button variant='outlined' onClick={onClose}>
                    {t('frontend.price_offers.create.demands_select.cancel')}
                </Button>
            </div>
        )
    }

    return (
        <FormProvider {...ordersForm}>
            <Modal show={show} width='800px'>
                <ModalTitle>{t('frontend.price_offers.sent_to_production_modal.title')}</ModalTitle>
                <ModalText>
                    <div className='flex mb-4 mt-8 text-gray w-full'>
                        {t('frontend.price_offers.sent_to_production_modal.description')}
                    </div>
                    {isLoadingDeliveryDate && isLoadingPriceOfferInfo ? (
                        <Skeleton count={3} width='100' className='my-2' height={40} />
                    ) : (
                        <div>
                            <OrderDemandSendToProductionTable error={error} form={ordersForm} data={data} />
                        </div>
                    )}
                    {isDpdExpedition && <Column cols={12}>{renderDpdDescription()}</Column>}
                    <div className='flex justify-center mt-4'>{renderActions()}</div>
                </ModalText>
            </Modal>
        </FormProvider>
    )
}

export default PriceOfferSendToProductionModal
