import { AxiosError } from 'axios'
import { PdfPart } from '../../../services/api/pdf-generator/PdfGeneratorService.types'
import { generatePdf } from '../../../services/api/pdf-generator/PdfGeneratorService'
import { useMutation } from '@tanstack/react-query'
import useAxios from '../../useAxios'

const useGeneratePdf = () => {
    const axiosInstance = useAxios()

    return useMutation<Blob, AxiosError, { parts: PdfPart[] }>(['generatePdf'], async ({ parts }) => {
        return await generatePdf(axiosInstance, parts)
    })
}

export default useGeneratePdf
