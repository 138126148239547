import { AxiosError } from 'axios'
import { MakeAxiosPaginatedResponse } from '../../../services/Service.types'
import { PriceOfferShort } from '../../../services/api/price-offer/PriceOffer.types'
import { priceOfferDelete } from '../../../services/api/price-offer/PriceOffer.service'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import useAxios from '../../useAxios'

const usePriceOfferDelete = () => {
    const axiosInstance = useAxios()
    const queryClient = useQueryClient()

    return useMutation<void, AxiosError, number>(
        ['deletePriceOffer'],
        id => {
            return priceOfferDelete(axiosInstance, id)
        },
        {
            onSuccess: (data, id) => {
                queryClient.removeQueries(['priceOffer', id], { exact: true })
                queryClient.setQueriesData<MakeAxiosPaginatedResponse<PriceOfferShort>>(['priceOffers'], prev => {
                    return {
                        ...prev,
                        data: prev.data
                            .filter(item => {
                                return item.id !== id
                            })
                            .map(item => {
                                return {
                                    ...item,
                                    variants: item.variants.filter(item => {
                                        return item.id !== id
                                    })
                                }
                            })
                    }
                })
            }
        }
    )
}

export default usePriceOfferDelete
