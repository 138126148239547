import {
    ComponentType,
    Dispatch,
    ForwardedRef,
    PropsWithChildren,
    SetStateAction,
    createContext,
    forwardRef,
    useCallback,
    useContext,
    useState
} from 'react'
import { PriceOfferShort } from '../../../../services/api/price-offer/PriceOffer.types'
import { PriceOfferTableRef } from './PriceOfferTable'
import { head, orderBy } from 'lodash-es'
import SelectDemandDialog from '../select-demand-dialog/SelectDemandDialog'

export type SelectedVariantInRow = {
    id: number
    variant: string
}

export type DemandSelectorState = {
    show: boolean
    showForId?: number
}

export type PriceOfferTableContextState = {
    selectedVariantInRows: SelectedVariantInRow[]
    setSelectedVariantInRows: Dispatch<SetStateAction<SelectedVariantInRow[]>>
    demandSelector?: DemandSelectorState
    setDemandSelector: (state: DemandSelectorState) => void
}

const PriceOfferTableContext = createContext<PriceOfferTableContextState>({} as PriceOfferTableContextState)

export const usePriceOfferTableContext = () => {
    const { setSelectedVariantInRows, selectedVariantInRows, setDemandSelector, demandSelector } =
        useContext(PriceOfferTableContext)

    const getSelectedVariant = useCallback(
        (rowId: number, variants: PriceOfferShort[] = []) => {
            const foundRow = selectedVariantInRows.find(row => {
                return row.id === rowId
            })
            const sortedVariantsByUpdatedAt = orderBy(variants, ['updatedAt'], ['desc'])
            const selectedVariant = (head(sortedVariantsByUpdatedAt) as PriceOfferShort)?.variant || '0'
            return foundRow ? foundRow.variant : selectedVariant
        },
        [selectedVariantInRows]
    )

    const selectVariant = useCallback(
        (rowId: number, variant: string) => {
            setSelectedVariantInRows(prevRows => {
                const row = prevRows.find(row => {
                    return row.id === rowId
                })
                if (!row) {
                    return [...prevRows, { id: rowId, variant }]
                }
                return prevRows.map(row => {
                    return row.id === rowId ? { ...row, variant } : row
                })
            })
        },
        [setSelectedVariantInRows]
    )

    const showAddOrdersToPriceOfferDialog = (priceOfferId?: number) => {
        return setDemandSelector({
            showForId: priceOfferId,
            show: true
        })
    }

    return {
        setSelectedVariantInRows,
        selectedVariantInRows,
        getSelectedVariant,
        selectVariant,
        demandSelector,
        setDemandSelector,
        showAddOrdersToPriceOfferDialog
    }
}

export const PriceOfferTableProvider = ({ children }: PropsWithChildren<{}>) => {
    const [selectedVariantInRows, setSelectedVariantInRows] = useState<SelectedVariantInRow[]>([])
    const [demandSelector, setDemandSelector] = useState<DemandSelectorState>()

    return (
        <PriceOfferTableContext.Provider
            value={{
                selectedVariantInRows,
                setSelectedVariantInRows,
                demandSelector,
                setDemandSelector
            }}
        >
            {children}
            <SelectDemandDialog />
        </PriceOfferTableContext.Provider>
    )
}

export const withPriceOfferTableProvider = <P extends Record<string, unknown>>(WrappedComponent: ComponentType<P>) => {
    const WithPriceOfferTableProvider = (props: P, ref: ForwardedRef<PriceOfferTableRef>) => {
        const context = useContext(PriceOfferTableContext)

        if (context) {
            return <WrappedComponent ref={ref} {...props} />
        }

        return (
            <PriceOfferTableProvider>
                <WrappedComponent ref={ref} {...props} />
            </PriceOfferTableProvider>
        )
    }

    return forwardRef(WithPriceOfferTableProvider)
}

export default PriceOfferTableContext
