import { PriceOfferStepTwoDetail } from '../../../../services/api/price-offer/PriceOffer.types'
import { generatePriceOfferPdfParts } from './PriceOfferPdfRenderer.utils'
import { useCallback, useEffect, useState } from 'react'
import downloadFile from 'js-file-download'
import useAxios from '../../../../hooks/useAxios'
import useGeneratePdf from '../../../../hooks/api/generate-pdf/useGeneratePdf'
import usePrint from '../../../../hooks/usePrint'
import useUserCurrency from '../../../../hooks/useUserCurrency'

export type PriceOfferPdfRendererProps = {
    data?: PriceOfferStepTwoDetail
    onStartLoading?: () => void
    onEndLoading?: () => void
}

const usePriceOfferPdfRenderer = ({ data, onStartLoading, onEndLoading }: PriceOfferPdfRendererProps = {}) => {
    const axios = useAxios()
    const currency = useUserCurrency()
    const { mutateAsync: generatePdf } = useGeneratePdf()
    const printPdf = usePrint()
    const [priceOfferPdfBlob, setPriceOfferPdfBlob] = useState<Blob>()
    const [isPriceOfferPdfLoading, setIsPriceOfferPdfLoading] = useState<boolean>(false)

    const generatePriceOfferPdf = useCallback(
        async (customData: PriceOfferStepTwoDetail = data) => {
            if (!customData) {
                return
            }
            onStartLoading?.()
            setIsPriceOfferPdfLoading(true)

            // TODO pdf CURRENCY
            const parts = await generatePriceOfferPdfParts(axios, customData, customData.currency ?? currency)

            return generatePdf(
                {
                    parts
                },
                {
                    onSuccess: blob => {
                        setPriceOfferPdfBlob(blob)
                        onEndLoading?.()
                        setIsPriceOfferPdfLoading(false)
                    },
                    onError: () => {
                        onEndLoading?.()
                        setIsPriceOfferPdfLoading(false)
                    }
                }
            )
        },
        [axios, currency, data, generatePdf, onEndLoading, onStartLoading]
    )

    useEffect(() => {
        if (!data) {
            return
        }
        generatePriceOfferPdf(data)
    }, [data, generatePriceOfferPdf])

    const downloadPriceOfferPdf = useCallback(
        async (customData: PriceOfferStepTwoDetail = data) => {
            let blob: Blob
            if (!priceOfferPdfBlob) {
                blob = await generatePriceOfferPdf(customData)
            } else {
                blob = priceOfferPdfBlob
            }
            downloadFile(blob, `${customData.header.number}.pdf`)
        },
        [data, generatePriceOfferPdf, priceOfferPdfBlob]
    )

    const printPriceOfferPdf = useCallback(
        async (customData: PriceOfferStepTwoDetail = data) => {
            let blob: Blob
            if (!priceOfferPdfBlob) {
                blob = await generatePriceOfferPdf(customData)
            } else {
                blob = priceOfferPdfBlob
            }
            await printPdf(blob)
        },
        [data, generatePriceOfferPdf, priceOfferPdfBlob, printPdf]
    )

    const openPriceOfferPdfInNewTab = useCallback(
        async (customData: PriceOfferStepTwoDetail = data) => {
            let blob: Blob
            if (!priceOfferPdfBlob) {
                blob = await generatePriceOfferPdf(customData)
            } else {
                blob = priceOfferPdfBlob
            }
            const pdfUrl = URL.createObjectURL(blob)
            const pdfTitle = `${customData.header.number}.pdf`

            const newTab = window.open()
            if (newTab) {
                newTab.document.title = pdfTitle

                const iframe = document.createElement('iframe')
                iframe.style.width = '100%'
                iframe.style.height = '100vh'
                iframe.style.border = 'none'
                iframe.src = pdfUrl

                iframe.onload = function () {
                    newTab.focus()
                    iframe.contentWindow.print()
                }

                newTab.document.body.innerHTML = ''
                newTab.document.body.appendChild(iframe)
            }
        },
        [data, generatePriceOfferPdf, priceOfferPdfBlob]
    )

    return {
        generatePriceOfferPdf,
        priceOfferPdfBlob,
        isPriceOfferPdfLoading,
        downloadPriceOfferPdf,
        printPriceOfferPdf,
        openPriceOfferPdfInNewTab
    }
}

export default usePriceOfferPdfRenderer
